<template>
  <div class="selectMallOrderDetail">
    <div class="content_title">
      <div class="left"></div>
      <div class="text">基本信息</div>
    </div>
    <el-form label-width="100px">
      <div class="form_box">
        <el-form-item label="退款ID:">
          {{orderdetail.prod_id}}
        </el-form-item>
      </div>
      <div class="form_box">
        <el-form-item label="购买总金额:">
          {{orderdetail.prod_total_price}}
        </el-form-item>
        <el-form-item label="购买积分:">
          {{orderdetail.prod_integral}}
        </el-form-item>
      </div>
      <div class="form_box">
        <el-form-item label="优惠金额:">
          {{orderdetail.prod_coupon_price}}
        </el-form-item>
        <el-form-item label="实付金额:">
          {{orderdetail.order_balance}}
        </el-form-item>
      </div>
      <div class="form_box">
        <el-form-item label="退款积分:">
          {{orderdetail.prod_exit_integral}}
        </el-form-item>
        <el-form-item label="退款金额:">
          {{orderdetail.prod_exit_balance}}
        </el-form-item>
      </div> 
      <el-form-item label="订单状态:">
        <span style="margin-right:10px;">
        {{ orderdetail.prod_status==1?'待付款' :orderdetail.prod_status==2?'待发货' :orderdetail.prod_status==3?'待收货/待提货' :orderdetail.prod_status==4?'待评价' :orderdetail.prod_status==5?'申请退款' :orderdetail.prod_status==6?'同意退款,待用户退货' :orderdetail.prod_status==7?'用户已退货,待商家确认' :orderdetail.prod_status==8?'退货成功' :orderdetail.prod_status==9?'已完成' :orderdetail.prod_status==10?'交易取消' :'未知状态'}}
         </span> 
        <el-button size="mini" type="primary" @click="toApply(1)" v-if="orderdetail.prod_status==5">同意申请</el-button>
        <el-button size="mini" type="primary" @click="toApply(2)" v-if="orderdetail.prod_status==5">拒绝申请</el-button>
        <el-button size="mini" type="primary" @click="toConfirm()" v-if="orderdetail.prod_status==7">确认退款</el-button>
      </el-form-item>
    </el-form> 
    <div>
      <div class="content_title">
        <div class="left"></div>
        <div class="text">退货原因</div>
      </div>
      <el-form label-width="100px">
        <div class="form_box">
          <el-form-item label="货物状态:">
            {{orderdetail.prod_exit_status}}
          </el-form-item>
          <el-form-item label="退款原因:">
            {{orderdetail.prod_exit_cause}}
          </el-form-item>
        </div>
        <el-form-item label="退款说明:">
          {{orderdetail.prod_exit_explain}}
        </el-form-item>
        <el-form-item label="退款凭证:">
          <el-image 
            v-for="(url, index) in orderdetail.prod_exit_prove" :key="index"
            style="margin:0 20px 20px 0;width:100px; height:100px;"
            :src="url" 
            :preview-src-list="orderdetail.prod_exit_prove">
          </el-image>
        </el-form-item>
      </el-form>
    </div> 
    <div v-if="orderdetail.prod_exit_express_name">
      <div class="content_title">
        <div class="left"></div>
        <div class="text">退货物流</div>
      </div>
      <el-form label-width="100px">
        <div class="form_box">
          <el-form-item label="物流名称:">
            {{orderdetail.prod_exit_express_name}}
          </el-form-item>
          <el-form-item label="物流单号:">
            {{orderdetail.prod_exit_express_coding}}
          </el-form-item>
        </div>
      </el-form>
    </div> 
    <div class="content_title">
      <div class="left"></div>
      <div class="text">店铺信息</div>
    </div>
    <el-table border :data="storeInfo" tooltip-effect="dark" style="margin-top:10px; width:1000px;">
      <el-table-column label="ID" prop="store_id" width="50"></el-table-column>
      <el-table-column label="店铺名称" prop="store_name"></el-table-column>
      <el-table-column label="店铺封面">
        <template slot-scope="{row}">
          <el-image style="width:50px; height:50px" :src="row.store_home_img" :preview-src-list="[row.store_home_img]"></el-image>
        </template>
      </el-table-column>
    </el-table>
    
    <div class="content_title">
      <div class="left"></div>
      <div class="text">商品信息</div>
    </div>
    <el-table border :data="prodInfo" tooltip-effect="dark" style="margin-top:10px; width:1000px;">
      <el-table-column label="ID" prop="prod_id" width="50"></el-table-column>
      <el-table-column label="商品名称" prop="prod_name"></el-table-column>
      <el-table-column label="商品封面">
        <template slot-scope="{row}">
          <el-image style="width:50px; height:50px" :src="row.prod_home_img" :preview-src-list="[row.prod_home_img]"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="商品规格">
        <template slot-scope="{row}">
          {{row.prod_one_item_name||''}} {{row.prod_two_item_name||''}} {{row.prod_three_item_name||''}}
        </template>
      </el-table-column>
      <el-table-column label="商品价格" prop="prod_buy_price"></el-table-column>
      <el-table-column label="商品积分" prop="prod_buy_integral"></el-table-column>
      <el-table-column label="购买数量" prop="prod_buy_count"></el-table-column>
    </el-table>
    
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="rejectVisible"
      title="发货"
      width="width"
    >
      <el-form :rules="rejectRule" :model="applyInfo" ref="rejectRuleForm" label-width="80px">
        <el-form-item label="拒绝原因" prop="remark">
          <el-input v-model="applyInfo.remark" placeholder="请输入拒绝原因" style="width:300px;"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="rejectVisible = false">取 消</el-button>
        <el-button type="primary" @click="toReject()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="sureVisible"
      title="发货"
      width="width"
    >
      <el-form :rules="rejectRule" :model="applyInfo" ref="rejectRuleForm" label-width="80px">
        <el-form-item label="退款金额" prop="remark">
          <el-input-number :step="1" :min="0" v-model="applyInfo.exitBalance" style="width:300px;"></el-input-number>
        </el-form-item>
        <el-form-item label="退款积分" prop="remark">
          <el-input-number :step="1" :min="0" v-model="applyInfo.exitIntegral" style="width:300px;"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="sureVisible = false">取 消</el-button>
        <el-button type="primary" @click="toSure()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { returnDetail, returnApply, returnApplyCancel, returnConfirm } from '../../../api/selectMall.js'
  export default {
    name:'selectMallOrderDetail',
    data() {
      return {
        orderdetail:{},
        prodInfo:[],
        userInfo:[],
        storeInfo:[],
        
        rejectVisible:false,
        sureVisible: false,
        applyInfo:{
          remark:''
        },
        rejectRule:{
          mask:[{ required:true, message:'请输入拒绝原因', trigger:'blur'} ],
        },
      }
    },
    created() {
      console.log('路由', this.$route)
      this.getDetail()
    },
    methods:{
      async getDetail() {
        const { data } = await returnDetail({
          orderProdId:this.$route.query.id
        })
        if (data.code==0) {
          if (data.data.prod_exit_prove) {
            data.data.prod_exit_prove = data.data.prod_exit_prove.split(',')
          } else {
            data.data.prod_exit_prove = []
          }
          this.orderdetail = data.data
          this.prodInfo = [this.orderdetail]
          this.userInfo = [this.orderdetail.userInfo]
          this.storeInfo = [this.orderdetail.storeInfo]
        }
      },
      toApply(status) {
        this.applyInfo.prod_id = this.orderdetail.prod_id
        if (status==1) {
          this.applyInfo.exitBalance = this.orderdetail.prod_exit_balance
          this.applyInfo.exitIntegral = this.orderdetail.prod_exit_integral
          this.sureVisible=true
        } if (status==2) {
          this.applyInfo.remark=''
          this.rejectVisible=true
        }
      },
      async toReject() {
        const { data } = await returnApplyCancel({
          orderProdId:this.orderdetail.prod_id,
          refuseCause:this.applyInfo.remark
        })
        if (data.code==0) {
          this.$message.success(data.msg)
          this.rejectVisible = false
          this.getDetail()
        } else {
          this.$message.error(data.msg)
        }
      },
      async toSure() {
        if (!this.applyInfo.exitIntegral||!this.applyInfo.exitBalance) {
          return this.$message.error('请填写完整数据!')
        }
        const { data } = await returnApply({
          orderProdId:this.orderdetail.prod_id,
          exitIntegral: this.applyInfo.exitIntegral,
          exitBalance: this.applyInfo.exitBalance,
        })
        if (data.code==0) {
          this.$message.success(data.msg)
          this.sureVisible = false
          this.getDetail()
        } else {
          this.$message.error(data.msg)
        }
      },
      toConfirm() {
        this.$confirm('确认已收到退货吗?', '提示', {
          confirmBottonText:'确认',
          cancelBottomText:'取消',
          type:'warning',
          center:true
        })
        .then(async () => {
          const { data } = await returnConfirm({
            orderProdId:this.orderdetail.prod_id,
          })
          if (data.code==0) {
            this.$message.success(data.msg)
            this.rejectVisible = false
            this.getDetail()
          } else {
            this.$message.error(data.msg)
          }
        })
      },
    }
  }
</script>

<style lang="less" scoped="scoped">
  .selectMallOrderDetail {
    .el-breadcrumb {
      height:35px;
      border-bottom:1px solid rgb(192, 191, 191);
    }

    .return {
      margin-top:50px;
    }

    .el-table {
      margin-top:30px;
    }

    .content_title {
      display:flex;
      align-items:center;
      margin-top:20px;

      .left {
        width:5px;
        height:13px;
        background:#6a8abe;
        display:inline-block;
        margin-right:8px;
      }
    }

    .form_box {
      display:flex;
      width:600px;

      &>div {
        flex:1;
      }
    }
  }
</style>
